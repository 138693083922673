import React, { useEffect, useState } from 'react';
import { useIsClient } from 'usehooks-ts';

const RemoteComponent: React.FC<{
  loadComponentModuleFn: () => Promise<{ default: React.ComponentType<unknown> }>;
  props?: Record<string, unknown>;
}> = ({ loadComponentModuleFn, props }) => {
  const isClient = useIsClient();
  const [Component, setComponent] = useState<React.FC<unknown> | null>(null);

  useEffect(() => {
    if (isClient) {
      const loadComponent = React.lazy(() => loadComponentModuleFn?.());
      setComponent(loadComponent);
    }
  }, [isClient, loadComponentModuleFn]);

  return isClient && Component ? <Component {...props} /> : null;
};

export default RemoteComponent;
