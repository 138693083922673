import React, { PropsWithChildren } from 'react';
import Head from 'next/head';
import Navbar from '../Navbar';
import styles from '../../styles/common.module.scss';

export default function Layout({ children }: PropsWithChildren) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Navbar />
      <main className={styles.componentsContainer}>{children}</main>
    </>
  );
}
