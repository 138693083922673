import { EVENTS, PubSub, UserStore } from '@rc-d2c/context-manager';
import { RcButton } from '@rc-d2c/shared-ui';
import React from 'react';
import Link from 'next/link';
import { useStore } from 'zustand';
import Login from '../Login';

import NavLink from '../NavLink';
import RemoteComponent from '../../pages/components/RemoteComponent';
import styles from '../../styles/common.module.scss';
// @ts-expect-error the path is unkown because it belongs to the federated module
const cartPanelToggleRemote = () => import('cart/cartPanelToggle');

export default function Navbar() {
  const toggleTranslationKeys = () => {
    PubSub.publish(EVENTS.DEBUG_TRANSLATIONS_KEYS);
  };
  const { isMswEnable, setIsMswEnable } = useStore(UserStore, (state) => state);

  const isClient = 'undefined' !== typeof window;
  const toggleMSW = () => {
    if (!isClient) {
      return;
    }

    setIsMswEnable(!isMswEnable);

    window.location.replace(window.location.origin);
  };

  return (
    <header className={styles.header}>
      <h1 className={styles.title}>
        D2C Shell application
        <small className={styles.version}>CM v{process.env.contextManagerVersion}</small>
        <RcButton
          customClasses={styles.debug_translations_button}
          onClick={toggleTranslationKeys}
          label="Toggle translation keys"
          variant="hyperlink"
        />
        <RcButton
          customClasses={styles.debug_translations_button}
          onClick={toggleMSW}
          label={isMswEnable ? 'Disable MSW' : 'Enable MSW'}
          variant="hyperlink"
        />
      </h1>

      <div className={styles.navigationContainer}>
        <div className={styles.linksContainer}>
          <NavLink path="/" label="Home" />
          <NavLink path="/components-demo" label="Components" />
          <NavLink path="/subscriptions/list" label="Subscriptions" version={process.env.subscriptionMfeVersion} />
          <NavLink path="/cart" label="Cart" version={process.env.cartMfeVersion} />
          <NavLink path="/my-account" label="MEA" />
          <NavLink path="/recommended-products" label="Recommended products" />
          <NavLink path="/reorder-products" label="Reorder products" />
          <NavLink path="/addresses" label="Addresses" />
          <NavLink path="/checkout" label="Checkout" version={process.env.checkoutMfeVersion} />
        </div>
        <RemoteComponent loadComponentModuleFn={cartPanelToggleRemote} props={{ linkComponent: Link }} />
      </div>
      <div className={styles.loginButtonContainer}>
        <Login />
      </div>
    </header>
  );
}
